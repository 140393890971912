type FacilityTypeDealProps = {
  facility_type: number;
  facility_type_custom: string;
};

function FacilityTypeDeal({
  facility_type,
  facility_type_custom,
}: FacilityTypeDealProps) {
  switch (facility_type) {
    case 1:
      return 'Cheap Park Deal';
    case 2:
      return 'Best Seller';
    case 3:
      return 'Hot Deal';
    case 4:
      return 'Last Minute';
    case 5:
      return 'Promotion';
    case 6:
      return 'Cheapest';
    case 7:
      return 'Closest';
    case 8:
      return 'Park and Ride';
    case 9:
      return facility_type_custom;
  }
}

export default FacilityTypeDeal;

