/* eslint-disable @next/next/no-img-element */
import { CDN2 } from '@/helpers/api';
import React from 'react';

export interface ILowestPriceGuarantee {
  isCardList?: boolean;
}

function LowestPriceGuarantee({ isCardList }: ILowestPriceGuarantee) {
  return (
    <div
      className={`flex flex-col h-auto border rounded-md bg-[#069B27]/10 mt-4 ${
        isCardList ? 'w-[13rem] md:w-11/12 p-1' : 'w-20 p-2'
      }`}
    >
      <div
        className={`flex  text-center px-2 md:px-2 ${
          isCardList ? 'flex-row justify-start' : 'flex-col items-center'
        }`}
      >
        <img
          src={CDN2() + '/staticmyapp/lpguarantee.svg'}
          className="z-0 object-cover w-4 md:w-5 h-5 md:h-6 mt-0.5"
          alt="image"
        />
        <p
          className={`text-[0.6rem] font-bold ${
            isCardList ? 'mt-1 ml-2  md:text-xs' : 'md:text-[0.688rem]'
          }`}
        >
          Lowest Price Guarantee{isCardList ? '' : '*'}
        </p>
      </div>
      {isCardList && (
        <p className="text-[0.625rem] ml-2 mt-1.5 md:text-base">
          We’ll pay you the difference if you find the same deal cheaper after
          booking.
        </p>
      )}
    </div>
  );
}

export default LowestPriceGuarantee;
