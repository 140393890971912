import React, { Fragment } from 'react';
import HighlightIcon from '../HighlightIcon';

export default function SidePopover({
  highlights,
  facility,
  size = null,
  ...props
}) {
  const checkedHighlights = highlights.filter((highlight) => highlight.check);
  return (
    <>
      <div
        className={`flex flex-col md:flex-row justify-between gap-2 md:gap-4 self-center items-start`}
        {...props}
      >
        {checkedHighlights.map((highlight, index) => (
          <Fragment key={highlight.type + index}>
            {highlight.type === 'facility_shuttle_frequency' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Shuttle Frequency</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_free_shuttles' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="free-shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Free Shuttle</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_no_shuttles' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="no-free-shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col lg:flex-row gap-1 lg:gap-2 justify-center items-start`}
                >
                  <span className="font-bold">No Shuttle</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_distance' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="distance"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Distance</span>
                  <span>
                    {highlight.description
                      ? highlight.description
                      : `${facility.facility_airport_distance} ${
                          facility.facility_airport_distance === 1
                            ? 'mile'
                            : 'miles'
                        }`}
                  </span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_free_cancellation' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="free-cancellations"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Free Cancellations</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_accessibility' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="accessible"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Wheelchair Accessible</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_open_hour' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="open_hour"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  stroke="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Facility Access</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_eletric_car' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="eletric_car"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">EV Charger</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}

            {highlight.type === 'facility_additional_comments' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="additional_comments"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Note</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )}
            {/* {highlight.type === 'facility_extra_free_cancellation' && (
              <div className="flex flex-row justify-start items-start ">
                <HighlightIcon
                  type="extra_fee_cancellation"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p
                  className={`${
                    size === 'small' ? 'text-xs' : 'text-sm'
                  } text-left ml-2 flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold">Extra Fee Cancellation</span>
                  <span>{highlight.description && highlight.description}</span>
                </p>
              </div>
            )} */}
          </Fragment>
        ))}
      </div>
    </>
  );
}
