/* eslint-disable jsx-a11y/alt-text */
import { CDN2 } from '@/helpers/api';
import { FrownOutlined } from '@/components2/Icons';
import { Button, Input, AutoComplete, Select } from 'antd';
import Image from 'next/image';
import { useState } from 'react';
import SearchStore from '@/stores/SearchStore';
import { useMobxStores } from '@/stores';
import { modalType } from './Modal';

const { Option } = Select;

interface INoData {
  withoutBtn?: boolean;
  imageNoData?: boolean;
  iconNoData?: boolean;
  bgImageWhite?: boolean;
  textNoData: string;
  // doSubscribe?: () => void;
  // onChange?: any;
  // email?: string;
  withSubscribe?: boolean;
  airportId?: any;
}

export default function NoData({
  withoutBtn,
  imageNoData,
  iconNoData,
  bgImageWhite,
  textNoData,
  // doSubscribe,
  // onChange,
  // email,
  withSubscribe,
  airportId,
}: INoData) {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [options, setOptions] = useState([]);
  const [email, setEmail] = useState<string>('');

  const handleEmailChange = (value) => {
    setSelectedDomain(value);
    setOptions([]);
  };

  const domains = [
    '@gmail.com',
    '@yahoo.com',
    '@hotmail.com',
    '@live.com',
    '@outlook.com',
  ];

  const handleSearch = (value) => {
    const domainIndex = value.lastIndexOf('@'); // Last position of "@" in the string
    if (domainIndex !== -1) {
      const username = value.substring(0, domainIndex); // Part before "@"
      const domain = value.substring(domainIndex); // Part after "@"
      const filteredOptions = domains.filter(
        (d) => d.indexOf(domain) !== -1 // Filter domains that contain the part after "@"
      );
      if (filteredOptions.length > 0) {
        setOptions(
          filteredOptions.map((d) => username + d) // Combine username with filtered domains
        );
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (value) => {
    if (value.includes('@')) {
      setSelectedDomain(value);
      if (setEmail) {
        setEmail(value);
      }
    } else {
      setSelectedDomain(selectedDomain + value);
      if (setEmail) {
        setEmail(selectedDomain + value); // Append selected domain if missing
      }
    }
  };

  const doSubscribe = async () => {
    if (email) {
      await searchStore.subscribe(airportId, email);
      modalType({
        type: 'success',
        title: 'Success',
        okButtonProps: {
          className: 'bg-primary',
        },
        content: (
          <p>
            Got it! Watch your inbox for updates on new facilities. See you
            soon!
          </p>
        ),
        onOk: () => {
          setEmail('');
        },
      });
      setEmail('');
    }
  };

  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-col justify-center items-center md:mb-12 px-4 ${
          bgImageWhite ? 'pt-8' : 'pt-0'
        }`}
      >
        {imageNoData && (
          <Image
            alt="No Data"
            src={
              bgImageWhite
                ? CDN2() + '/staticmyapp/no-data-white.webp'
                : CDN2() + '/staticmyapp/no-data-lightorange.webp'
            }
            width={412}
            height={227}
          />
        )}
        {iconNoData && (
          <FrownOutlined className="text-primary font-bold text-7xl" />
        )}
        <span className="text-base md:text-3xl font-bold mt-8 mb-4 text-center">
          {textNoData}
        </span>

        {withoutBtn ? (
          <></>
        ) : (
          <a className="w-full text-center mt-2" href="/">
            <Button className="w-full md:w-64 h-10 font-bold rounded-full border border-white hover:border-primary text-white hover:text-primary bg-primary hover:bg-white mb-6 md:mb-0">
              Search again
            </Button>
          </a>
        )}
        {withSubscribe && (
          <>
            <span className="text-base md:text-xl font-bold mt-8 mb-4 text-center">
              Sign up to get notified when a new facility opens in your area!
            </span>
            <AutoComplete
              value={email}
              onChange={handleEmailChange}
              options={options.map((domain) => ({ value: domain }))}
              onSelect={handleSelect}
              onSearch={handleSearch}
              placeholder="Enter your email"
              className="w-full md:w-64"
            >
              <Input
                type="email"
                className="rounded-full w-full md:w-64"
                inputMode="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                allowClear
              />
            </AutoComplete>

            <Button
              className="w-full md:w-64 h-10 font-bold rounded-full border border-white hover:border-primary text-white hover:text-primary bg-primary hover:bg-white mb-6 md:mb-0 mt-4"
              onClick={doSubscribe}
            >
              Subscribe
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
